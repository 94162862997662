/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://r3det403o7.execute-api.eu-north-1.amazonaws.com/prod",
            "region": "eu-north-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://agtiq4zwp5a4vgpqiyhxhbiwje.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-north-1:bb1e48e8-0997-4d39-935b-15788be3031d",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_R9DrIshny",
    "aws_user_pools_web_client_id": "69tdsj70cngr6pijokqrt9r7pi",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "likabehandlignsplan-hosting-prod",
    "aws_content_delivery_bucket_region": "eu-north-1",
    "aws_content_delivery_url": "https://d25nbd8t3i5p0l.cloudfront.net",
    "aws_user_files_s3_bucket": "likabehandlingsplan39effe698d494957a569d444d56b223524-prod",
    "aws_user_files_s3_bucket_region": "eu-north-1"
};


export default awsmobile;
